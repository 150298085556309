import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { MARKS, BLOCKS } from "@contentful/rich-text-types";

const WineModal = (content) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <span className="fw-bold">{text}</span>,
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <img
          className="w-50 m-auto"
          src={node.data?.target?.fields?.file?.url}
          alt={node.data?.target?.fields?.title}
        />
      ),
    },
  };
  const wineArt = content.content.wineArt.fields.file.url;
  const {
    content: { wineName, description, theName, theWine, theGrape, keywords },
  } = content;
  const renderGrape = () => {
    return (
      <div className="m-t-30">
        <h3>The Grape</h3>
        {documentToReactComponents(theGrape, options)}
      </div>
    );
  };

  const renderName = () => {
    return (
      <div className="m-t-30">
        <h3>The Name</h3>
        {documentToReactComponents(theName, options)}
      </div>
    );
  };

  const renderWine = () => {
    return (
      <div className="m-t-30">
        <h3>The Wine</h3>
        {documentToReactComponents(theWine, options)}
      </div>
    );
  };

  const renderKeywords = () => {
    return (
      <p className="i small-text">
        {keywords.map((kw, i, arr) => {
          if (i === arr.length - 1) {
            return <span>{kw}</span>;
          } else {
            return <span>{kw}, </span>;
          }
        })}
      </p>
    );
  };

  return (
    <div>
      <div className="wine-modal">
        <div className="d-flex flex-column align-items-center">
          <img
            className="wine-modal-art"
            src={wineArt}
            alt={wineName + " label art"}
          />
          <h2 className="m-t-20">{wineName}</h2>
        </div>
        {keywords && renderKeywords()}
        <p className="m-t-50">{description}</p>
        {theWine && renderWine()}
        {theGrape && renderGrape()}
        {theName && renderName()}
      </div>
    </div>
  );
};

export default WineModal;

import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import WineModal from "../components/WineModal";
import classnames from "classnames";
import Dessert from "../images/dessert.png";
import CornerLadybug from "../images/corner/corner-ladybug-art.webp";
import Logo from "../images/logo-dark.svg";
import SheWolf from "../images/shewolf-art.png";
import Starters from "../images/starters.png";
import Pizza from "../images/menu-pizza.png";
import Piadine from "../images/piadine.png";
import Affogato from "../images/menu-affogato.png";
import WineItem from "../components/WineItem";
import Section from "../components/Section";

const Menu = ({ items, cellarWines, hours, title }) => {
  const [wineModalInfo, setWineModalInfo] = useState(false);
  useEffect(() => {
    document.title = `Auburn Road - ${title}`;
    return () => {
      document.title = "Auburn Road Vineyards";
    };
  }, [title]);
  let winesObj = {};

  items.forEach((item) => {
    const categories = item.metadata.tags;
    if (!categories.length > 0) {
      return null;
    }
    categories.forEach((category) => {
      const tag = category.sys.id;
      if (!winesObj[tag]) {
        winesObj[tag] = [item];
      } else {
        winesObj[tag].push(item);
      }
    });
  });

  const renderCellarWines = () => {
    if (cellarWines.length > 0) {
      return cellarWines.map((item) => {
        const { name, vintage, description, price } = item.fields;
        return (
          <div className="max-width-475 m-t-20 p-r-20 flex-grow-1">
            <div className="m-b-0 menu-title fw-bold font-size--large d-flex align-items-center">
              {name} &nbsp;
              <div className="background-color-wine color-white px-1 my-0 menu-title d-inline-block border-radius-5 font-size--small">
                {vintage}
              </div>
            </div>
            <span className="fw-bold">${price}</span>
            <br />
            <span className="m-b-0 menu-title"></span>
            <p className="m-b-5">{description}</p>
          </div>
        );
      });
    } else return null;
  };

  const renderWines = () => {
    const showWines = (type, column) => {
      const wines = type.map((x) => (
        <WineItem key={x.sys.id} wine={x} setWineModalInfo={setWineModalInfo} />
      ));
      return (
        <div
          className={classnames("m-auto d-flex flex-wrap", {
            "flex-column": column,
          })}
        >
          {wines}
        </div>
      );
    };

    return (
      <div>
        {showWines(winesObj["flights"], true)}
        <div className="d-flex flex-md-row m-t-100 flex-column flex-md-column">
          <div className="flex-1">
            <div className="text-center m-b-20">
              <span className="text-center menu-underlined-text-2" name="white">
                DRY WHITE AND BLUSH
              </span>
            </div>
            {showWines(winesObj["white"])}
          </div>
          <div className="flex-1 m-t-75 m-md-t-100">
            <div className="text-center m-b-20" name="sweet">
              <span className="text-center menu-underlined-text">
                SWEET AND FRUIT
              </span>
            </div>
            {showWines(winesObj["sweetFruit"])}
          </div>
          <div className="flex-1 m-t-75 m-md-t-100">
            <div className="text-center m-b-20">
              <span
                className="text-center menu-underlined-text-2"
                name="sparkling"
              >
                SPARKLING
              </span>
            </div>
            {showWines(winesObj["sparkling"])}
          </div>
        </div>
        <div className="d-flex flex-md-row m-t-100 flex-column flex-md-column">
          <div className="text-center m-b-20">
            <span className="text-center menu-underlined-text" name="red">
              DRY RED
            </span>
          </div>
          <div className="flex-1">{showWines(winesObj["red"])}</div>
          <div className="flex-1 m-t-75 m-md-t-50">
            <div className="text-center m-b-20">
              <span
                className="text-center menu-underlined-text-2"
                name="fortified"
              >
                FORTIFIED WINES
              </span>
            </div>
            {showWines(winesObj["fortified"])}
          </div>
        </div>
        {cellarWines && (
          <div className="d-flex flex-column flex-md-column m-t-100">
            <div className="flex-1 m-t-75 m-md-t-0">
              <div className="background-color-wine px-5 py-4 max-width-500 m-auto color-white">
                <h2 name="cellar">Cellar Wines</h2>
                <p className="my-0">
                  Available to Wine CSA Members only and to participants in
                  Guided Premium Tastings on days when offered.
                </p>
                <p className="">**Bottle sales only</p>
              </div>
              <div className="m-auto d-flex flex-wrap my-2">
                {renderCellarWines()}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <img className="corner-ladybug" src={CornerLadybug} alt="" />
      <div className="container p-t-150 menu-wrap">
        <img src={Logo} className="width-250 m-auto" alt="" />
        <h1 className="m-b-100 m-t-15 text-center">Menu</h1>
        <p>
          <i>
            *Auburn Road is pleased to offer you a 2.5% discount if you pay with
            cash
          </i>
          <br />
          <i>**There is an automatic 20% gratuity on parties of 5 or more.</i>
        </p>
        <div className=" m-auto">
          <h2 className="huge-text-no-quote m-b-40" name="wine">
            Wine
          </h2>
          {renderWines()}
          <Modal
            show={wineModalInfo}
            component={WineModal}
            closeFn={() => setWineModalInfo(false)}
            content={wineModalInfo}
          />
          <div className=" m-auto">
            <div className="m-auto d-flex flex-sm-row  m-t-150">
              <div>
                <h2 className="m-b-30 huge-text-no-quote" name="starters">
                  Starters
                </h2>
                <Section
                  items={items}
                  category="Starter"
                  lastItem={() => (
                    <div>
                      <div className="m-b-0 menu-title font-size--large">
                        A La Carte:
                      </div>
                      <span>
                        Bowl of Spanish Nuts &emsp; 4<br />
                      </span>
                      <span>
                        Gluten Free Crackers &emsp; 2.5
                        <br />
                      </span>
                      <span>
                        Crostini Crackers &emsp; 5<br />
                      </span>
                      <span>
                        Woodfire House-baked Flatbread &emsp; 4.5
                        <br />
                      </span>
                      <span>Castelvetrano Olives &emsp; 4.5</span>
                    </div>
                  )}
                />
              </div>
              <div className="flex-2 menu-pizza">
                <img className="w-100 " src={Starters} alt="starters" />
              </div>
            </div>
          </div>
          <div className=" mt-5 pt-5">
            <h2
              className="m-t-100 m-b-30 huge-text-no-quote"
              name="soup & salad"
            >
              Soup & Salad
            </h2>
            <div className="d-flex">
              <Section items={items} category="Soup Salad" />
            </div>
          </div>
          <div className=" mt-5 pt-5">
            <h2 className="m-t-100 huge-text-no-quote" name="piadine">
              Piadine
            </h2>
            <p className="m-b-40 max-width-400">
              Delicious Italian street sandwiches made with house-baked bread
              from our wood -fired oven.
            </p>
            <div className="d-flex">
              <Section items={items} category="Piadine" />
              <div className="flex-1  menu-piadine">
                <img className="w-75" src={Piadine} alt="piadine" />
              </div>
            </div>
          </div>
          <h2 className="m-b-30 m-t-100 huge-text-no-quote" name="pizza">
            Wood-fired Pizza
          </h2>
          <p className="fw-bold bg-dark text-white width-300 py-2 p-l-10 m-b-75">
            Available ONLY on <br />
            {hours[0].fields.pizzaHours.map((x) => (
              <span className="hours-item" key={x.key}>
                <span className="b">{x.key}: </span>
                {x.value}
                <br />
              </span>
            ))}
          </p>
          <div className="m-auto d-flex flex-column flex-sm-row justify-content-between">
            <Section
              items={items}
              category="Pizza"
              lastItem={() => (
                <>
                  <div>**Add prosciutto, pepperoni or sausage - 2.5</div>
                  <div>**Add mushroom or onion - 1.5</div>
                </>
              )}
            />
            <div className="flex-1">
              <img className="w-75 menu-pizza" src={Pizza} alt="pizza" />
            </div>
          </div>
        </div>
      </div>

      <img src={SheWolf} className="menu-shewolf" alt="" />
      <div className="container p-t-50">
        <div className="inner-container m-auto">
          <h2
            className="text-center m-b-75 m-t-150 huge-text-no-quote"
            name="dessert"
          >
            Desserts
          </h2>
          <div className="m-auto d-flex flex-column flex-sm-row justify-content-between">
            <Section items={items} category="Dessert" />
            <div className="flex-2">
              <img
                className="w-100 menu-dessert"
                src={Dessert}
                alt="auburn road dessert"
              />
            </div>
          </div>
        </div>
        <div className="inner-container m-auto m-t-75 m-b-150">
          <h2
            className="text-center m-b-75 m-t-200 huge-text-no-quote"
            name="drinks"
          >
            Coffee and other beverages
          </h2>
          <div className="m-auto d-flex flex-column flex-sm-row justify-content-between">
            <Section items={items} category="Coffee" />
            <div className="flex-2">
              <img
                className="w-100 menu-coffee"
                src={Affogato}
                alt="affogato"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;

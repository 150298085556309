import React, { useState } from "react";
import Modal from "./Modal";
import AllDates from "../components/AllDates";
import moment from "moment";
import NoImage from "../images/no-image.png";
import classnames from "classnames";
import Button from "../components/Button.js";

const CURRENT_MONTH = moment().startOf("month");

const formatDate = (date) => moment(date).format("ddd, MMM Do");
const formatMonthKey = (date) => moment(date).format("YYYY-MM");
const formatDisplayMonth = (date) => moment(date).format("MMMM YYYY");

const LiveVineyardStage = ({ info }) => {
  const getAllDatesByArtist = (name) => {
    const artist = info.filter((x) => x.fields.name === name);
    if (artist.length) {
      return artist[0].fields;
    }
    return null;
  };

  const [selectedArtist, setSelectedArtist] = useState(null);
  const ArtistCard = ({ fields, date, selectFn }) => {
    const website = fields.websites && fields.websites[0];
    const { name, location, artistImage } = fields;
    const imageUrl = artistImage?.fields.file.url;

    return (
      <div className="card">
        <div className="card-image">
          <img className="w-100" src={imageUrl || NoImage} alt={name} />
        </div>
        <div className="card-body p-3">
          <p className="m-b-0 p-b-10">{formatDate(date)}</p>
          <h4 className="mb-0">{name}</h4>
          <p className="small-text m-b-3">
            {location && <span>from {location}</span>}
          </p>
          {website && (
            <p className="pb-4">
              <a
                href={website}
                target="_blank"
                rel="noopener noreferrer"
                className="me-2"
              >
                website
              </a>
            </p>
          )}
          <Button
            className=""
            type="secondary"
            small
            onClick={() => selectFn(name)}
          >
            See all dates
          </Button>
        </div>
      </div>
    );
  };

  const groupArtistsByMonth = (list) => {
    const grouped = list.reduce((acc, artist) => {
      if (!artist.fields.date) {
        return acc;
      }
      const futureDates = artist.fields.date.filter((date) =>
        moment(date).isAfter(CURRENT_MONTH)
      );
      futureDates.forEach((date) => {
        const monthKey = formatMonthKey(date);
        if (!acc[monthKey]) {
          acc[monthKey] = {
            displayMonth: formatDisplayMonth(date),
            artists: [],
          };
        }
        acc[monthKey].artists.push({ ...artist, date });
        acc[monthKey].artists.sort((a, b) =>
          moment(a.date, "M/D/YY").diff(moment(b.date, "M/D/YY"))
        );
      });
      return acc;
    }, {});

    return grouped;
  };

  const renderArtists = (list, selectFn) => {
    const groupedByMonth = groupArtistsByMonth(list);

    return Object.entries(groupedByMonth)
      .sort(([monthA], [monthB]) => moment(monthA).diff(moment(monthB)))
      .map(([month, { displayMonth, artists }], idx) => {
        return (
          <div key={month}>
            <h2
              className={classnames("m-b-30", {
                "m-t-100": idx > 0,
              })}
            >
              {displayMonth}
            </h2>
            <div className="d-flex flex-wrap flex-column flex-md-row gap-3">
              {artists.map((artist) => (
                <ArtistCard
                  key={artist.date}
                  fields={artist.fields}
                  date={artist.date}
                  selectFn={selectFn}
                />
              ))}
            </div>
          </div>
        );
      });
  };

  return (
    <div className="container p-t-100 m-b-50">
      <h1 className="huge-text-no-quote">LIVE!</h1>
      <h2 className="">from the vineyard stage</h2>
      <p>
        Every Saturday Night, we welcome amazing performers from the region,
        Philly and beyond.
      </p>
      <Modal
        show={selectedArtist}
        component={AllDates}
        closeFn={() => setSelectedArtist(null)}
        content={getAllDatesByArtist(selectedArtist)}
      />
      <div className="m-t-100 p-b-100">
        {renderArtists(info, setSelectedArtist)}
      </div>
    </div>
  );
};

export default LiveVineyardStage;

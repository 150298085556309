import React from "react";
import classnames from "classnames";

const WineItem = ({ wine, setWineModalInfo }) => {
  const {
    glassPrice,
    soldout,
    bottlePrice,
    item,
    itemPrice,
    longDescription,
    smallDescription,
    wine: wineField,
  } = wine.fields;

  const hyphenWineName =
    wineField &&
    wineField.fields &&
    wineField.fields.wineName.split(" ").join("-").toLowerCase();
  const renderItemPrice =
    glassPrice || bottlePrice
      ? `${bottlePrice} / ${glassPrice || "bottle only"}`
      : itemPrice;
  const renderSoldout = !soldout ? (
    <span className="fw-bold">{renderItemPrice}</span>
  ) : (
    <span className="text-danger">sold out</span>
  );

  return (
    <div
      className={classnames("max-width-475 m-t-20 p-r-20", {
        soldout: soldout,
      })}
    >
      <span className="m-b-0 menu-title fw-bold font-size--large">
        {item} &emsp;
        {renderSoldout}
      </span>
      {smallDescription && (
        <p className="i font-size--small">{smallDescription}</p>
      )}
      <p className="m-b-5">{longDescription}</p>
      {hyphenWineName && (
        <div className="styled-link">
          <span
            className="m-t-10"
            onClick={() => setWineModalInfo(wineField.fields)}
          >
            learn more
          </span>
        </div>
      )}
    </div>
  );
};

export default WineItem;

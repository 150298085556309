import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { options } from "../utils/contentfulOptions";

const MenuItem = ({ menuItem, category }) => {
  const soldout = menuItem.soldout;
  let item;
  if (category === "Pizza") {
    const splitItem = menuItem.item.split("/");
    item = (
      <p>
        <span className="fw-bold">{splitItem[0]}</span>
        <span>/{splitItem[1]}</span>
      </p>
    );
  } else {
    item = menuItem.item;
  }

  return (
    <div className="p-r-20">
      <span className="m-b-0 fw-bold menu-title font-size--large">
        {item} &emsp;
        {!soldout ? (
          <span className="fw-bold">
            {menuItem.itemPrice
              ? menuItem.itemPrice
              : `${menuItem.bottlePrice + " / " + menuItem.glassPrice}`}
          </span>
        ) : (
          <span className="text-danger">sold out</span>
        )}
      </span>
      {menuItem.smallDescription && (
        <p className="i font-size--small">{menuItem.smallDescription}</p>
      )}
      <p className="">
        {menuItem.richTextDesc
          ? documentToReactComponents(menuItem.richTextDesc, options)
          : menuItem.longDescription}
      </p>
    </div>
  );
};

export default MenuItem;

import React from "react";
import MenuItem from "./MenuItem";

const Section = ({ items, category, lastItem }) => {
  const itemList = items.filter((x) => x.fields.category.includes(category));
  return (
    <div className="d-flex flex-column-reverse justify-content-end flex-wrap flex-2 max-width-600">
      {lastItem && lastItem()}
      {itemList.map(
        (x) =>
          x &&
          x.fields.item && (
            <MenuItem key={x.sys.id} menuItem={x.fields} category={category} />
          )
      )}
    </div>
  );
};

export default Section;

import React from "react";
import moment from "moment";
const AllDates = ({ content }) => {
  const { date, name, websites } = content;
  const imageUrl = content.artistImage && content.artistImage.fields.file.url;
  return (
    <div className="all-dates">
      <img className="img" src={imageUrl} alt={name} />
      <div>
        <div className="my-4">
          <h3
            onClick={() => window.open(websites[0])}
            className="artist pointer"
          >
            {name}
          </h3>
          <p>Upcoming dates at Auburn Road</p>
        </div>

        <div className="d-flex flex-column gap-3">
          <ul>
            {date.map((date, index) => (
              <li key={index} className="mb-0 upcoming-date">
                {moment(date).format("dddd, MMM Do")}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AllDates;
